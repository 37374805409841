import React from 'react'
import { css } from 'linaria'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../elements/Hero'

import { Subheading, HeroHeading, Lead } from 'components/TextElements'
import SectionContent from '../components/Section/Content'
import Button from '../components/Button'
import breakpoints from '../utils/tokens/breakpoints'
import rhythm from '../utils/rhythm'

const heroContent = (
  <div
    className={css`
      ${{
        paddingLeft: rhythm(1),
        paddingRight: rhythm(1)
      }};
    `}
  >
    <Subheading>Join our team</Subheading>
    <HeroHeading>Career at Bejamas</HeroHeading>
    <Lead>
      We are a team of passionate web developers who share the vision of
      websites, that are high-performing, safe, and easy to maintain.
    </Lead>
    <Button to="mailto:hr@bejamas.io">Apply</Button>
  </div>
)

export default ({ location, data }) => (
  <Layout
    location={location}
    title="Career at Bejamas"
    description="We are a team of passionate web developers who share the vision of websites, that are high-performing, safe, and easy to maintain."
    image="https://bejamas-care.now.sh/**Career%20at%20Bejamas**.png?theme=light&md=1&fontSize=75px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
  >
    <Hero content={heroContent} image={data.hero} />
    <Section variant="lightGray">
      <SectionContent
        wide
        rowGap="0"
        className={css`
          ${{ marginTop: 0, gridTemplateColumns: '60%' }}
        `}
      >
        <h2>Open positions</h2>
        <Lead>
          We don't have open positions currently. However, you can always drop
          an email just to stay in touch. We will inform you once recruitment is
          open.
        </Lead>
      </SectionContent>
    </Section>
    <Section>
      <SectionContent
        wide
        rowGap="0"
        className={css`
          ${{
            marginTop: 0
          }}
        `}
      >
        <div>
          <h2>Benefits</h2>
          <Lead>
            Join us, gain independence and work from anywhere you like. Bejamas'
            Team works on two continents right now in 5 different locations.
            Learn from your team mates and teach them at the same time in a
            highly open-minded environment.
          </Lead>
        </div>
        <div>
          <h2>Culture</h2>
          <Lead>
            As Digital Nomads we believe in the importance of truthness and
            radical open-mindedness in our relationships. Not only in our team,
            but also with our clients. The truth and willingness to achieve the
            best outcome is valued higher than 'just being right'.
          </Lead>
        </div>
      </SectionContent>
    </Section>
  </Layout>
)

export const query = graphql`
  query CareersPageQuery {
    hero: file(
      relativePath: { regex: "/img/footer/" }
      name: { eq: "become-one-of-us@2x" }
    ) {
      childImageSharp {
        fluid(maxWidth: 513, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
